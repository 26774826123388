import React, { useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
const axios = require("axios");

import Navigation from "../components/Navigation";

import Layout from "../components/layout";
import { Link } from "gatsby";
import "../styles/main.scss";
import ColorButton from "../components/ColorButton";

import { Helmet } from 'react-helmet';

export default function KostenlosTesten() {
    const [ sentPopoverOpen, setSentPopoverOpen ] = React.useState(false);
    const [ confirmPopoverOpen, setConfirmPopoverOpen ] = React.useState(false);
    const [ formInformation, setFormInformation ] = React.useState({});
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ strasseError, setStrasseError ] = useState(false);
    const [ plzError, setPlzError ] = useState(false);
    const [ anzahlSchuelerError, setAnzahlSchuelerError ] = useState(false);
    const [ mailError, setMailError ] = useState(false);
    const [ telError, setTelError ] = useState(false);
    const [ datenschutzError, setDatenschutzError ] = useState(false);
    const [ schulart, setSchulart ] = useState("");
    const [ formRef, setFormRef ] = useState("");
    const [ popoverMail, setPopoverMail ] = useState("");
    const divRef = React.useRef();
    const vornameRef = useRef();
    const nachnameRef = useRef();
    const strasseRef = useRef();
    const plzRef = useRef();
    const stadtRef = useRef();
    const landRef = useRef();
    const schulnameRef = useRef();
    const schulartRef = useRef();
    const anzahlSchuelerRef = useRef();
    const mailRef = useRef();
    const telefonRef = useRef();
    const datenschutzRef = useRef();
    const honeypotNameRef = useRef();
    const honeypotMailRef = useRef();
    const honeypotTelRef = useRef();
    const [ formResponse, setFormResponse ] = useState("");
    const newsletterRef = useRef();

    const schularten = [
        { value: "", label: "" },
        { value: "Grundschule", label: "Grundschule" },
        { value: "Realschule", label: "Realschule" },
        { value: "Gesamtschule", label: "Gesamtschule" },
        { value: "Gymnasium", label: "Gymnasium" },
        { value: "Berufsschule", label: "Berufsschule" },
        { value: "Andere Schule", label: "Andere Schule" },
    ];

    let formData = {};

    const handleChange = (e) => {
        setSchulart(e.target.value);
    };

    const handleConfirmPopoverClose = () => {
        setConfirmPopoverOpen(false);
        setAnchorEl(null);
    };
    const handleSentPopoverClose = () => {
        setSentPopoverOpen(false);
        setAnchorEl(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let _access = 1;
        if (honeypotNameRef.current.value || honeypotMailRef.current.value || honeypotTelRef.current.value) {
            _access = 0;
        }
        const vorname = vornameRef.current.value;
        const nachname = nachnameRef.current.value;
        const strasse = strasseRef.current.value;
        const plz = plzRef.current.value;
        const stadt = stadtRef.current.value;
        const land = landRef.current.value;
        const schulname = schulnameRef.current.value;
        setSchulart(schulartRef.current.value);
        const anzahlSchueler = anzahlSchuelerRef.current.value;
        setPopoverMail(mailRef.current.value);
        const mail = (mailRef.current.value);
        const telefon = telefonRef.current.value;
        const mailRegExp = new RegExp("^[A-Za-z0-9\\.\\!\\#\\$\\%\\&\\'\\*\\+\\-\\\\/\\=\\?\\^\\_\\`\\{\\|\\}\\~]+\\@[A-Za-z0-9\\.\\!\\#\\$\\%\\&\\'\\*\\+\\-\\\\/\\=\\?\\^\\_\\`\\{\\|\\}\\~]+\\.(?:\\w{2,6})$");
        let error = false;
        if (plz && !plz.match(/^[0-9]{4,5}$/)) {
            setPlzError(true);
            error = true;
        } else {
            setPlzError(false);
        }
        if (!anzahlSchueler.match(/^[0-9]*$/)) {
            error = true;
            setAnzahlSchuelerError(true);
        } else {
            setAnzahlSchuelerError(false);
        }
        if (strasse && !strasse.match(/^[A-Za-z\u00C0-\u00FF]+.{0,1}\s[0-9]+\w{0,2}$/)) {
            error = true;
            setStrasseError(true);
        } else {
            setStrasseError(false);
        }
        if (!mail.match(mailRegExp)) {
            error = true;
            setMailError(true);
        } else {
            setMailError(false);
        }
        if (telefon && !telefon.match(/^\+{0,1}[0-9]{2,3}[\s/-]{1}[0-9]{2,4}[\s/-]{0,1}[0-9]{3,8}[\s/-]{0,1}[0-9]{0,6}$/)) {
            error = true;
            setTelError(true);
        } else {
            setTelError(false);
        }
        if (!datenschutzRef.current.checked) {
            error = true;
            setDatenschutzError(true);
            return;
        } else {
            setDatenschutzError(false);
        }
        formData = { vorname, nachname, strasse, land, plz, stadt, schulname, schulart, anzahlSchueler, mail, telefon, _access };
        if (error) {
            return;
        }
        if (mail) {
            await sendForm(formData);
            error = false;
        }
    };

    async function postForm() {
        try {
            const { to, subject, body, _access } = formInformation;
            const response = await axios.post(`${window.config.NODEMAILER_URL}/send`,
                { to, subject, body, _access },
                { headers: {
                    "content-type": "application/json"
                }
                });
            if (response.status >= 200 < 300) {
                console.log(response.status);
                let greeting = "Vielen Dank!";
                setFormResponse(
                    <>
                        <div className="formSuccess">
                            <h2 className="formSuccess">
                                {greeting}
                            </h2>
                            Wir haben Ihre Daten erfolgreich entgegengenommen und werden Sie in den nächsten Tagen unter der von Ihnen angegebenen E-Mail-Adresse kontaktieren.
                        </div>
                        <br />
                        <ColorButton className="formSubmit" aria-describedby={sentPopoverId} onClick={handleSentPopoverClose}>
                            Schließen
                        </ColorButton>
                    </>
                );
                formRef.reset();
                setSchulart("");
            }
        } catch (err) {
            if (err.response) {
                const mailto = `mailto:hilfe@fehlzeitenerfassung.de?subject=Unbekannter%20Fehler%20${err.response.status}%20bei%20der%20Test-Account-Anforderung`;
                setFormResponse(
                    <>
                        <div className="formFail">
                            <h2 className="formFail">
                                Wir konnten Ihre Daten leider nicht entgegennehmen.
                            </h2>
                            Falls dieser Fehler später erneut auftritt, kontaktieren Sie bitte den <b><a href={mailto}>Support</a></b>!
                        </div>
                        <br />
                        <ColorButton className="schliessen" aria-describedby={sentPopoverId} onClick={handleSentPopoverClose}>
                            Schließen
                        </ColorButton>
                    </>
                );
            } else {
                setFormResponse(
                    <>
                        <div className="formFail">
                            <h2 className="formFail">
                                Unerwarteter Serverfehler
                            </h2>
                            Bitte versuchen Sie es später erneut. Falls dieser Fehler später erneut auftritt, kontaktieren Sie bitte den <b><a href="mailto:hilfe@fehlzeitenerfassung.de?subject=Unbekannter%20Serverfehler%20bei%20der%20Test-Account-Anforderung">Support</a></b>.
                        </div>
                        <br />
                        <ColorButton className="schliessen" aria-describedby={sentPopoverId} onClick={handleSentPopoverClose}>
                            Schließen
                        </ColorButton>
                    </>
                );
            }
        } finally {
            setAnchorEl(divRef.current);
            setSentPopoverOpen(true);
            handleConfirmPopoverClose();
        }
    }

    async function sendForm(formData) {
        let subject = "Anfrage Test-DB Fehlzeitenerfassung";
        const { vorname, nachname, strasse, land, plz, stadt, schulname, schulart, anzahlSchueler, mail, telefon, _access } = formData;
        let newsletter;
        if (newsletterRef.current.checked) {
            newsletter = "Ja";
        } else {
            newsletter = "Nein";
        }
        if (_access != 1) {
            const mailto = "mailto:hilfe@fehlzeitenerfassung.de?subject=%20Fehler%20bei%20einer%20Rückrufbitte%20(hidden%20input%20filled)";
            setFormResponse(
                <>
                    <div className="formFail">
                        <h2 className="formFail">
                            Wir konnten Ihre Daten leider nicht entgegennehmen.
                        </h2>
                        Bitte kontaktieren Sie den <b><a href={mailto}>Support</a></b>.
                    </div>
                    <br />
                    <ColorButton className="schliessen" aria-describedby={sentPopoverId} onClick={handleSentPopoverClose}>
                        Schließen
                    </ColorButton>
                </>
            );
            setSentPopoverOpen(true);
            return;
        }
        if (vorname && nachname) {
            subject += ` von ${vorname} ${nachname}`;
        } else {
            subject += ` von der Schule ${schulname}`;
        }
        const to = "kontakt@fehlzeitenerfassung.de";
        const body = 
            "Neue Anfrage von:\n" +
            `Vorname: ${vorname || "unbekannt"}\n` +
            `Nachname: ${nachname || "unbekannt"}\n\n` +
            `Straße: ${strasse || "unbekannt"}\n` +
            `Postleitzahl: ${plz || "unbekannt"}\n` +
            `Stadt: ${stadt || "unbekannt"}\n` +
            `Land: ${land || "unbekannt"}\n\n` +
            `Name der Schule: ${schulname || "unbekannt"}\n` +
            `Schulart: ${schulart || "unbekannt"}\n` +
            `Anzahl der Schüler: ${anzahlSchueler || "unbekannt"}\n\n` +
            `E-Mail-Adresse: ${mail}\n` +
            `Telefon: ${telefon || "unbekannt"}\n` +
            `Newsletter: ${newsletter}`;

        setFormInformation({ to, subject, body, _access });
        setConfirmPopoverOpen(true);
    }

    const sentPopoverId = sentPopoverOpen ? "form-popover" : undefined;
    const confirmPopoverId = confirmPopoverOpen ? "form-popover" : undefined;

    const sentPopover = (
        <div>
            <Popover
                id={sentPopoverId}
                open={sentPopoverOpen}
                anchorEl={anchorEl}
            >
                {formResponse}
            </Popover>
        </div>
    );

    const confirmationPopover = (
        <div>
            <Popover
                id={confirmPopoverId}
                open={confirmPopoverOpen}
                anchorEl={anchorEl}
            >
                <p className="confirmationPopover">
                    Bitte prüfen Sie vor dem Absenden, ob die von Ihnen eingetragene E-Mail-Adresse <b className="mailConfirmation">{popoverMail}</b> korrekt ist.
                    <br />
                    Wenn Sie Ihre E-Mail-Adresse geprüft haben, können Sie entweder <b>"Schließen"</b> klicken,
                    um sie zu korrigieren, oder Sie klicken auf <b>Absenden</b>, um das Formular endgültig abzusenden.
                </p>
                <div className="confirmationButtons">
                    <ColorButton className="schliessen" aria-describedby={confirmPopoverId} onClick={handleConfirmPopoverClose}>
                        Schließen
                    </ColorButton>
                    <ColorButton className="formSubmit" aria-describedby={confirmPopoverId} onClick={postForm}>
                        Absenden
                    </ColorButton>
                </div>
            </Popover>
        </div>
    );

    const form = (
        <div className="formContainer" ref={divRef}>
            <form ref={(el) => setFormRef(el)} className="testenForm" onSubmit={handleSubmit}>
                <div className="inputGroup">
                    <TextField inputProps={{ "ref": vornameRef }} label="Vorname:"  />
                    <TextField inputProps={{ "ref": nachnameRef }} label="Nachname:"  />
                </div>
                <div className="inputGroup">
                    { strasseError ? <h4 className="formError">Bitte geben Sie eine korrekte Straße mit Hausnummer ein, (z.B. Römerstraße 23)</h4> : "" }
                    <TextField inputProps={{ "ref": strasseRef }} label="Straße:" />
                    { plzError ? <h4 className="formError">Bitte geben Sie eine 4- oder 5-stellige Postleitzahl ein</h4> : "" }
                    <TextField inputProps={{ "ref": plzRef }} label="Postleitzahl:" />
                </div>
                <div className="inputGroup">
                    <TextField inputProps={{ "ref": stadtRef }} label="Stadt:" />
                    <TextField inputProps={{ "ref": landRef }} id="standard-basic" label="Land" />
                </div>
                <div className="inputGroup">
                    <TextField inputProps={{ "ref": schulnameRef }} label="Schulname:" />
                    <TextField
                        inputProps={{ "ref": schulartRef }}
                        id="standard-select"
                        select
                        label="Schulart"
                        value={schulart}
                        onChange={handleChange}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Bitte wählen Sie die Art Ihrer Schule aus"
                    >
                        {schularten.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </div>
                <div className="inputGroup">
                    { anzahlSchuelerError ? <h4 className="formError">Bitte geben Sie eine ganzzahlige Anzahl ein</h4> : "" }
                    <TextField inputProps={{ "ref": anzahlSchuelerRef }} label="Anzahl der Schüler:" />
                    { telError ? <h4 className="formError">Bitte geben Sie die Telefonnummer mit Länderkennung wie im unten stehenden Beispiel an.</h4> : ""}
                    <TextField inputProps={{ "ref": telefonRef }} label="Telefonnummer:" helperText="z.B. +49 2336 9242360" />
                </div>
                { mailError ? <h4 className="formError">Bitte überprüfen Sie die eingegebene E-Mail-Adresse auf Gültigkeit</h4> : "" }
                <TextField className="mailInput" inputProps={{ "ref": mailRef }} label="E-Mail-Adresse:" required={true} />
                {/* H o n e y p o t     fields */}
                <label className="ohnohoney" htmlFor="name"></label>
                <input className="ohnohoney" ref={honeypotNameRef} tabIndex="-1" autoComplete="off" type="text" id="name" name="name" placeholder="Your name here" />
                <label className="ohnohoney" htmlFor="email"></label>
                <input className="ohnohoney" ref={honeypotMailRef} tabIndex="-1" autoComplete="off" type="email" id="email" name="email" placeholder="Your e-mail here" />
                <label className="ohnohoney" htmlFor="tel"></label>
                <input className="ohnohoney" ref={honeypotTelRef} tabIndex="-1" autoComplete="off" type="tel" id="tel" name="tel" placeholder="Your phone number here" />
                <br/>
                <div className="newsletterCheck">
                    <div className="checkboxDiv">
                        <input ref={newsletterRef} className="newsletterCheckbox" type="checkbox" />
                    </div>
                    <p>
                        Ich möchte regelmäßig über Neuigkeiten zur Software per E-Mail informiert werden. 
                    </p>
                </div>
                { datenschutzError ? <h4 className="formError datenschutz">Sie können eine Testversion nur beantragen, wenn Sie die Datenschutzbedingungen zuvor zur Kenntnis nehmen.</h4> : "" }
                <div className="datenschutzCheck">
                    <div className="checkboxDiv">
                        <input ref={datenschutzRef} className="datenschutzCheckbox" type="checkbox"/>
                    </div>
                    <p>
                        Hiermit bestätige ich, dass ich die <a href="/datenschutz" target="_blank">Datenschutzbestimmungen</a> zur Kenntnis genommen habe.
                    </p>
                </div>
                <div>
                    <ColorButton className="formSubmit initial" type="submit">Absenden</ColorButton>
                </div>
            </form>
        </div>
    );

    return (
        <Layout>
            <Helmet>
                <script src="/config.js"></script>
            </Helmet>
            <Navigation path="/kostenlos-testen" />
            <h1 className="formHeading">Kostenlos testen</h1>
            <p>
                Wenn Sie <Link to="/">fehlzeitenerfassung.de</Link> kostenlos testen möchten, füllen Sie bitte das Formular aus, damit wir Ihnen Zugangsdaten zu einem Testaccount für die Webversion per Email zusenden können.
            </p>
            {sentPopover}
            {confirmationPopover}
            {form}
        </Layout>
    );
}

